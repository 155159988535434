import { Delete as DeleteIcon } from '@mui/icons-material';
import ColdIcon from '@mui/icons-material/AcUnit';
import CrowdedIcon from '@mui/icons-material/Groups';
import InaccessibleIcon from '@mui/icons-material/Lock';
import OtherIcon from '@mui/icons-material/ReportProblem';
import WaterIssueIcon from '@mui/icons-material/Science';
import { TimeFrame } from 'api/Serializers/Appointments';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { IS_DEV } from 'config';
import { getReportTypeDescription } from 'features/assistance/as-instructor/utils';
import { canReportOvercrowding } from 'features/assistance/utils';
import { AssistanceWizardProps } from 'models/Assistance';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';

const BeforeSameDay = (props: AssistanceWizardProps) => {
  const history = useHistory();
  useEffect(() => {
    history.replace(SHARED_ROUTES.SCHEDULE.appointment(props.appointment.id));
  }, []);
  return null;
};

const Locked = (props: AssistanceWizardProps) => {
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>Report issues with the pool or facility</h4>
      <Callout
        className="my-8"
        type="error"
        title="Closed"
        icon={<InaccessibleIcon color="inherit" fontSize="inherit" />}
      >
        It is now more than 48-hours after this lesson, and it cannot be
        modified
      </Callout>
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="I cannot access the pool"
          subtitle="Get immediate assistance gaining access to the pool"
          icon={<InaccessibleIcon className="text-gray-600" color="inherit" />}
          disabled={true}
        />
        <ButtonLarge
          title="There was a pool fouling"
          subtitle="Get immediate assistance when there is a pool fouling"
          icon={<DeleteIcon className="text-gray-600" color="inherit" />}
          disabled={true}
        />
        <ButtonLarge
          title="Urgent maintenance is needed"
          subtitle="Get immediate assistance for maintenance issues like water clarity or chemistry problems"
          icon={<WaterIssueIcon className="text-gray-600" color="inherit" />}
          disabled={true}
        />
        <ButtonLarge
          title="The pool is cold"
          subtitle="Report cold water issues and cancel if necessary"
          icon={<ColdIcon className="text-gray-600" color="inherit" />}
          disabled={true}
        />
        <ButtonLarge
          title="It's too crowded to teach"
          subtitle="Cancel the lesson if the pool is too crowded to safely continue"
          icon={<CrowdedIcon className="text-gray-600" color="inherit" />}
          disabled={true}
        />
        <ButtonLarge
          title="This facility needs attention"
          subtitle="Non-urgent issues such as cleanliness, lighting, or bathrooms needing attention"
          icon={<OtherIcon className="text-gray-600" color="inherit" />}
          disabled={true}
        />
      </div>
      <Controls variant="block">
        <BackButton />
      </Controls>
    </>
  );
};

// const PromptForLocation = ({ onClickContinue }) => {
//   return (
//     <Modal
//       open={true}
//       name="Report Location Required"
//       maxWidth="xs"
//       title="Your location is required"
//     >
//       <div className="space-y-5">
//         <Callout
//           type="info"
//           title="To submit a report, please share your location"
//           icon={<PlaceIcon width={24} />}
//         />
//         <p>
//           To improve the accuracy of reports given to hosts, your location is
//           required to continue. Without location access, your report will not be
//           accepted.
//         </p>
//         <p>
//           We respect your privacy, and your location will only be used for this
//           purpose.
//         </p>
//         <p className="text-sm italic text-center">
//           (Please select "Allow" on the next screen.)
//         </p>
//         <Controls variant="block">
//           <BackButton />
//           <Button color="primary" variant="contained" onClick={onClickContinue}>
//             Continue
//           </Button>
//         </Controls>
//       </div>
//     </Modal>
//   );
// };

// const LocationRequired = () => {
//   return (
//     <>
//       <Callout type="error" title="Location sharing was denied" />
//       <p>
//         To improve the accuracy of reports given to hosts, your location is
//         required to continue. Without location access, your report will not be
//         accepted.
//       </p>
//       <p>
//         To continue, you must reset the location permissions on your browser and
//         reload this page.
//       </p>
//       <Controls variant="block">
//         <BackButton />
//       </Controls>
//     </>
//   );
// };

const AssistanceAvailable = (props: AssistanceWizardProps) => {
  // const account = useSelector(getAccountDetail);
  // const [geolocation, setGeolocation] = useState();
  // const { permissionState, getLocationAsync } = useGeolocation(true);
  const { appointment, trackAction, addReportData } = props;
  const { timeFrame } = appointment;
  const canReportTimelyIssue = canReportOvercrowding(appointment);
  const handleSomethingElse = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_NEEDS_ATTENTION',
      title: 'What seems to be the problem?',
      description: getReportTypeDescription('FACILITY_NEEDS_ATTENTION'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'PROPEL_REVIEW',
      title: 'Next steps',
      description:
        'Propel will handle any further action needed by this submission',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'NO_CHANGE',
    });
  };

  // const trackGeolocation = async () => {
  //   const position = await getLocationAsync();
  //   addReportData({
  //     type: 'SET_MESSAGE_ADMIN',
  //     key: 'geolocation',
  //     value: `${JSON.stringify(position)}`,
  //   });
  //   console.log('Log!', position, JSON.stringify(position));
  // };

  // useEffect(() => {
  //   rudderanalytics.identify('Geolocation permission changed', {
  //     context: 'Reports',
  //     permissionState,
  //   });
  //   if (permissionState === 'granted') {
  //     trackGeolocation();
  //   }
  // }, [permissionState]);
  return (
    <div className="space-y-5">
      <h2>Facility Assistance</h2>
      <h4>Report issues with the pool or facility</h4>
      {/* {permissionState === 'prompt' ? (
        <PromptForLocation onClickContinue={trackGeolocation} />
      ) : permissionState === 'denied' ? (
        <LocationRequired />
      ) : ( */}
      <>
        <Callout className="my-8" type="question">
          What seems to be the problem?
        </Callout>
        <div className="my-8 space-y-3">
          <ButtonLarge
            title={
              timeFrame > TimeFrame.HasStarted
                ? 'I could not access the pool'
                : 'I cannot access the pool'
            }
            subtitle={
              timeFrame > TimeFrame.HasStarted
                ? 'Report when the pool was not open'
                : 'Get help or cancel the lesson when you cannot access the pool'
            }
            icon={
              <InaccessibleIcon className="text-gray-600" color="inherit" />
            }
            to={SHARED_ROUTES.ASSISTANCE.nav(
              props.appointment.id,
              'facility/inaccessible'
            )}
            onClick={() =>
              trackAction({
                type: 'SET_TYPE',
                key: 'type',
                value: 'FACILITY_INACCESSIBLE',
                title: 'What seems to be the problem?',
                description: getReportTypeDescription('FACILITY_INACCESSIBLE'),
              })
            }
          />
          <ButtonLarge
            title="There was a pool fouling"
            subtitle={
              timeFrame > TimeFrame.HasStarted
                ? 'Report when the lesson was cancelled because there was a pool fouling'
                : 'Get immediate assistance when there is a pool fouling'
            }
            icon={<DeleteIcon className="text-gray-600" color="inherit" />}
            to={SHARED_ROUTES.ASSISTANCE.nav(
              props.appointment.id,
              'facility/fouling'
            )}
            onClick={() =>
              trackAction({
                type: 'SET_TYPE',
                key: 'type',
                value: 'FACILITY_FOULING',
                title: 'What seems to be the problem?',
                description: getReportTypeDescription('FACILITY_FOULING'),
              })
            }
          />
          <ButtonLarge
            title={
              timeFrame > TimeFrame.HasStarted
                ? 'Maintenance stopped the lesson'
                : 'Urgent maintenance is needed'
            }
            subtitle={
              timeFrame > TimeFrame.HasStarted
                ? 'Report when the lesson was cancelled because the pool required maintenance'
                : 'Get help with maintenance issues like water clarity or chemistry problems'
            }
            icon={<WaterIssueIcon className="text-gray-600" color="inherit" />}
            to={SHARED_ROUTES.ASSISTANCE.nav(
              props.appointment.id,
              'facility/water-issues'
            )}
            onClick={() =>
              trackAction({
                type: 'SET_TYPE',
                key: 'type',
                value: 'FACILITY_URGENT_MAINTENANCE',
                title: 'What seems to be the problem?',
                description: getReportTypeDescription(
                  'FACILITY_URGENT_MAINTENANCE'
                ),
              })
            }
          />
          <ButtonLarge
            title="The pool is cold"
            subtitle={
              canReportTimelyIssue
                ? 'Report cold water issues and cancel if necessary'
                : `Only available during the lesson and for 10 minutes after`
            }
            icon={<ColdIcon className="text-gray-600" color="inherit" />}
            to={SHARED_ROUTES.ASSISTANCE.nav(
              props.appointment.id,
              'facility/cold'
            )}
            disabled={!canReportTimelyIssue}
            onClick={() =>
              trackAction({
                type: 'SET_TYPE',
                key: 'type',
                value: 'FACILITY_COLD',
                title: 'What seems to be the problem?',
                description: getReportTypeDescription('FACILITY_COLD'),
              })
            }
          />
          <ButtonLarge
            title="It is too crowded to teach"
            subtitle={
              canReportTimelyIssue
                ? 'Cancel the lesson if the pool is too crowded to safely continue'
                : `Only available during the lesson and for 10 minutes after`
            }
            disabled={!canReportTimelyIssue}
            icon={<CrowdedIcon className="text-gray-600" color="inherit" />}
            to={SHARED_ROUTES.ASSISTANCE.nav(
              props.appointment.id,
              'facility/overcrowded'
            )}
            onClick={() =>
              trackAction({
                type: 'SET_TYPE',
                key: 'type',
                value: 'FACILITY_CROWDED',
                title: 'What seems to be the problem?',
                description: getReportTypeDescription('FACILITY_CROWDED'),
              })
            }
          />
          <ButtonLarge
            title="This facility needs attention"
            subtitle="Non-urgent issues such as cleanliness, lighting, or bathrooms needing attention"
            icon={<OtherIcon className="text-gray-600" color="inherit" />}
            to={SHARED_ROUTES.ASSISTANCE.nav(
              props.appointment.id,
              'facility/needs-attention'
            )}
            onClick={handleSomethingElse}
          />
        </div>
        <Controls variant="block">
          <BackButton />
        </Controls>
      </>
      {/* )} */}
    </div>
  );
};

const SelectFacilityProblem = (props: AssistanceWizardProps) => {
  useEffect(() => {
    props.trackAction({
      type: 'SET_CATEGORY',
      key: 'category',
      value: 'FACILITY',
      title: 'What do you need help with?',
      description: 'Facility issues',
    });
  }, []);
  const { appointment } = props;
  if (!IS_DEV && appointment.timeFrame < TimeFrame.SameDay) {
    return <BeforeSameDay {...props} />;
  } else if (appointment.timeFrame < TimeFrame.Locked) {
    return <AssistanceAvailable {...props} />;
  } else {
    return <Locked {...props} />;
  }
};

export default SelectFacilityProblem;
