import { Event, TimeFrame } from 'api/Serializers/Appointments';
import { ReportResult } from 'models/Assistance';
import moment from 'moment-timezone';
import { getAppointmentTimeFrame } from 'utils/business-logic';

export const canCallHost = (appointment: Event) => {
  const timeFrame = getAppointmentTimeFrame(appointment);
  return (
    [TimeFrame.SameDay, TimeFrame.Within2, TimeFrame.HasStarted].indexOf(
      timeFrame
    ) !== -1
  );
};

export const canCallClient = (appointment: Event) => {
  const timeFrame = getAppointmentTimeFrame(appointment);
  return [TimeFrame.Within2, TimeFrame.HasStarted].indexOf(timeFrame) !== -1;
};

export const celsiusToFahrenheit = (temp: number) =>
  Math.round((temp * 9) / 5 + 32);
export const fahrenheitToCelsius = (temp: number) =>
  Math.round(((temp - 32) * 5) / 9);

export const overcrowdingReportStart = (appointment: Event) =>
  moment(appointment.start);
export const overcrowdingReportEnd = (appointment: Event) =>
  moment(appointment.end).add(10, 'minutes');
export const canReportOvercrowding = (appointment: Event) => {
  return moment().isBetween(
    overcrowdingReportStart(appointment),
    overcrowdingReportEnd(appointment),
    'minute',
    '[)'
  );
};

export const getReportResults = (result: ReportResult) => {
  switch (result) {
    case 'NO_CHANGE':
      return {
        isAppointmentCancelled: false,
        isClientRefunded: false,
        isInstructorCompensated: true,
        isHostPaid: true,
      };
    case 'START_INVESTIGATION':
      return {
        isAppointmentCancelled: false,
        isClientRefunded: false,
        isInstructorCompensated: true,
        isHostPaid: true,
      };
    case 'CANCELLATION':
      return {
        isAppointmentCancelled: true,
        isClientRefunded: true,
        isInstructorCompensated: false,
        isHostPaid: false,
      };
    case 'INSTRUCTOR_NO_SHOW':
      return {
        isAppointmentCancelled: true,
        isClientRefunded: true,
        isInstructorCompensated: false,
        isHostPaid: false,
      };
    case 'CLIENT_NO_SHOW':
      return {
        isAppointmentCancelled: true,
        isClientRefunded: false,
        isInstructorCompensated: true,
        isHostPaid: false,
      };
    case 'CLIENT_CAUSED_FOULING':
      return {
        isAppointmentCancelled: true,
        isClientRefunded: false,
        isInstructorCompensated: true,
        isHostPaid: false,
      };
    case 'FACILITY_NOT_AVAILABLE':
      return {
        isAppointmentCancelled: true,
        isClientRefunded: true,
        isInstructorCompensated: true,
        isHostPaid: false,
      };
    default:
      return undefined;
  }
};
