import { TimeFrame } from 'api/Serializers/Appointments';
import Button from 'components/button';
import Fab from 'components/button-fab';
import { DATE_FMT, IS_DEV } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { SettingsIcon } from 'icons';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state';
import { getAppointment } from 'state/selectors';
import { setAppointmentData } from 'state/slice/appointments';
import { resetReport } from 'state/slice/assistance';
import { SHARED_ROUTES } from 'utils/routing';

export function isPathnameInRouteRoot(pathname: string) {
  if (this.route.ROOT === '/') {
    // Edge case that causes Dashboard to be evaluated true
    // in every MenuItem breaking the fixed height settings
    return pathname === this.route.ROOT;
  }
  return pathname.indexOf(this.route.ROOT) !== -1;
}

export const unreadConversations = (state: RootState) =>
  state.messaging.conversations.reduce((final, convo) => {
    return convo.unreadMessageCount ? final.concat([convo]) : final;
  }, []);

export const AppointmentDebugToolbar = () => {
  if (!IS_DEV) {
    return null;
  }
  const appointment = useSelector(getAppointment);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const setTimeFrame = (timeFrame: TimeFrame) => {
    const timeFrameStartArray = [
      moment().startOf('hour').add(72, 'hours'), // Outside48
      moment().startOf('hour').add(42, 'hours'), // Within48
      moment().startOf('day').hour(19), // SameDay
      moment().startOf('hour').add(1, 'hours'), // Within2
      moment().subtract(5, 'minutes'), // HasStarted
      moment().startOf('hour').subtract(61, 'minutes'), // HasEnded
      moment().startOf('hour').subtract(121, 'minutes'), // Complete
      moment().startOf('hour').subtract(49, 'hours'), // Locked
    ];
    const start = timeFrameStartArray[timeFrame];
    const end = moment(start).add(60, 'minutes');
    dispatch(
      setAppointmentData({
        ...appointment,
        start: start.format(DATE_FMT.DATETIME_FIELD),
        end: end.format(DATE_FMT.DATETIME_FIELD),
        timeFrame,
      })
    );
  };
  const set10MinutesIn = () => {
    const start = moment().seconds(0).milliseconds(0).subtract(59, 'minutes');
    const end = moment(start).add(60, 'minutes');
    dispatch(
      setAppointmentData({
        ...appointment,
        start: start.format(DATE_FMT.DATETIME_FIELD),
        end: end.format(DATE_FMT.DATETIME_FIELD),
        timeFrame: TimeFrame.HasStarted,
      })
    );
  };
  if (!appointment) {
    return null;
  }
  return (
    <div
      className="fixed hidden grid-cols-1 gap-2 bottom-10 left-10 md:grid"
      style={{ zIndex: 1305 }}
    >
      <Fab icon={SettingsIcon} onClick={() => setIsOpen(!isOpen)} />
      {isOpen && (
        <>
          <Button
            onClick={() => setTimeFrame(TimeFrame.Outside48)}
            color={
              appointment.timeFrame === TimeFrame.Outside48
                ? 'primary'
                : 'default'
            }
            variant="contained"
            size="small"
          >
            Outside 48
          </Button>
          <Button
            onClick={() => setTimeFrame(TimeFrame.Within48)}
            color={
              appointment.timeFrame === TimeFrame.Within48
                ? 'primary'
                : 'default'
            }
            variant="contained"
            size="small"
          >
            Within 48
          </Button>
          <Button
            onClick={() => setTimeFrame(TimeFrame.SameDay)}
            color={
              appointment.timeFrame === TimeFrame.SameDay
                ? 'primary'
                : 'default'
            }
            variant="contained"
            size="small"
          >
            Same Day
          </Button>
          <Button
            onClick={() => setTimeFrame(TimeFrame.Within2)}
            color={
              appointment.timeFrame === TimeFrame.Within2
                ? 'primary'
                : 'default'
            }
            variant="contained"
            size="small"
          >
            Within 2
          </Button>
          <Button
            onClick={() => setTimeFrame(TimeFrame.HasStarted)}
            color={
              appointment.timeFrame === TimeFrame.HasStarted
                ? 'primary'
                : 'default'
            }
            variant="contained"
            size="small"
          >
            Has Started
          </Button>
          <Button
            onClick={set10MinutesIn}
            color="default"
            variant="contained"
            size="small"
          >
            15 Minutes In
          </Button>
          <Button
            onClick={() => setTimeFrame(TimeFrame.HasEnded)}
            color={
              appointment.timeFrame === TimeFrame.HasEnded
                ? 'primary'
                : 'default'
            }
            variant="contained"
            size="small"
          >
            Has Ended
          </Button>
          <Button
            onClick={() => setTimeFrame(TimeFrame.Complete)}
            color={
              appointment.timeFrame === TimeFrame.Complete
                ? 'primary'
                : 'default'
            }
            variant="contained"
            size="small"
          >
            Complete
          </Button>
          <Button
            onClick={() => setTimeFrame(TimeFrame.Locked)}
            color={
              appointment.timeFrame === TimeFrame.Locked ? 'primary' : 'default'
            }
            variant="contained"
            size="small"
          >
            Locked
          </Button>
          <Button
            to={SHARED_ROUTES.SCHEDULE.appointment(appointment.id)}
            onClick={() => dispatch(resetReport())}
            color="secondary"
            variant="contained"
            size="small"
          >
            Reset
          </Button>
        </>
      )}
    </div>
  );
};
