import React, { useEffect } from 'react';
import ClientDialThroughView from './views/client/call';
// import ClientChangeParticipantsView from './views/client/change-participants';
import { TimeFrame } from 'api/Serializers/Appointments';
import { IS_DEV } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AssistanceWizardProps } from 'models/Assistance';
import { AccountRouteParams } from 'models/route';
import { useSnackbar } from 'notistack';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { resetReport } from 'state/slice/assistance';
import { SHARED_ROUTES } from 'utils/routing';
import FacilityRootView from './views/facility';
import FacilityCallHostView from './views/facility/call';
import FacilityDescribeIssueView from './views/facility/cancel';
import FacilityColdView from './views/facility/cold';
import FacilityColdDecisionView from './views/facility/cold-decision';
import FacilityFoulingView from './views/facility/fouling';
import FacilityTooCrowdedView from './views/facility/overcrowded';
import InstructorCancellationView from './views/instructor';
import OtherRootView from './views/other';
import SummaryView from './views/summary';

const getPath = (route: string) =>
  SHARED_ROUTES.ASSISTANCE.nav(':appointmentId', route);

const ReportWizard = (props: AssistanceWizardProps) => {
  const { appointment } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { params } = useRouteMatch<AccountRouteParams>();
  const { action } = params;

  useEffect(() => {
    if (!IS_DEV && appointment.timeFrame === TimeFrame.Locked) {
      enqueueSnackbar({
        message:
          'It is now more than 24-hours after this lesson, and it cannot be modified',
        variant: 'error',
      });
      dispatch(resetReport());
      history.replace(SHARED_ROUTES.SCHEDULE.appointment(props.appointment.id));
    }
  }, [action]);

  return (
    <div className="w-full">
      <Switch>
        {/* <Route exact path={getPath('new')}>
          <SelectCategoryView {...props} />
        </Route> */}
        <Route exact path={getPath('summary')}>
          <SummaryView {...props} />
        </Route>
        {!appointment.cancelled && (
          <>
            <Route exact path={getPath('instructor-cancellation')}>
              <InstructorCancellationView {...props} onClose={history.goBack} />
            </Route>
            <Route exact path={getPath('client/call')}>
              <ClientDialThroughView {...props} />
            </Route>
            <Route exact path={getPath('facility')}>
              <FacilityRootView {...props} />
            </Route>
            <Route exact path={getPath('facility/fouling')}>
              <FacilityFoulingView {...props} />
            </Route>
            <Route exact path={getPath('facility/fouling-notify')}>
              <FacilityCallHostView {...props} />
            </Route>
            <Route exact path={getPath('facility/inaccessible')}>
              <FacilityCallHostView {...props} />
            </Route>
            <Route exact path={getPath('facility/water-issues')}>
              <FacilityCallHostView {...props} />
            </Route>
            <Route exact path={getPath('facility/cold')}>
              <FacilityColdView {...props} />
            </Route>
            <Route exact path={getPath('facility/overcrowded')}>
              <FacilityTooCrowdedView {...props} />
            </Route>
            <Route exact path={getPath('facility/needs-attention')}>
              <FacilityDescribeIssueView {...props} />
            </Route>
            <Route exact path={getPath('facility/cold-decision')}>
              <FacilityColdDecisionView {...props} />
            </Route>
            <Route exact path={getPath('facility/cancel')}>
              <FacilityDescribeIssueView {...props} />
            </Route>
            <Route exact path={getPath('general')}>
              <OtherRootView {...props} />
            </Route>
          </>
        )}
      </Switch>
    </div>
  );
};

export default ReportWizard;
