import { captureEvent, SeverityLevel } from '@sentry/react';
import { DecodedMediaUrl } from 'api/Serializers/Media';
import { HTTP_401_UNAUTHORIZED } from 'api/status';
import { AxiosError } from 'axios';
import { APP_RELEASE, IS_DEV, IS_SERVER, RUN_SENTRY_DEV } from 'config';
// we must force tsc to interpret this file as a module, resolves
// "Augmentations for the global scope can only be directly nested in external modules or ambient module declarations."
// error
export {};

interface Logger {
  log: (message?: any, ...extra: any[]) => void | string;
  info: (message?: any, ...extra: any[]) => void | string;
  warn: (message?: any, ...extra: any[]) => void | string;
  debug: (message?: any, ...extra: any[]) => void | string;
  error: (message?: any, ...extra: any[]) => void | string;
  time: (label?: string) => void;
  timeEnd: (label?: string) => void;
  captureAxiosError: (message: string, err: AxiosError) => void;
  captureEvent: ({
    level,
    message,
    extra,
  }: {
    level: SeverityLevel;
    message: string;
    extra: object;
  }) => void;
}

declare global {
  /**
   * Returns true if object is defined and usable. Checks whether null or undefined
   */
  function safe<T>(someObject: T | null | undefined): boolean;
  /**
   * Appends a className with space prefix, if the boolean evaluates to true or the object is truthy
   * @param bool If true, the className is applied
   * @param className className to apply when bool is true
   * @param classIfFalse (optional) className to apply when bool is false
   */
  function cls<T>(
    bool: T | null | undefined,
    className?: string,
    classIfFalse?: string
  ): string;
  function copyToClipboard<T>(text: string): void;
  function urlToImageServerUrl<T>(
    src: string,
    width?: number,
    height?: number,
    rotate?: number
  );
  function cookiesEnabled<T>(): boolean;
  // function track<T>({ }): void;
  const logger: Logger;
}

const _global = typeof window !== 'undefined' ? window : (global as any);
const deadEnd = (message?: any, ...extra: any[]) => null;
const debugLogger: Logger = {
  log: (message?: any, ...extra: any[]) =>
    console.log(`[${new Date().toJSON()}] LOG   -`, message, ...extra),
  info: (message?: any, ...extra: any[]) =>
    console.info(`[${new Date().toJSON()}] INFO  -`, message, ...extra),
  warn: (message?: any, ...extra: any[]) =>
    console.warn(`[${new Date().toJSON()}] WARN  -`, message, ...extra),
  debug: (message?: any, ...extra: any[]) =>
    console.debug(`[${new Date().toJSON()}] DEBUG -`, message, ...extra),
  error: (message?: any, ...extra: any[]) =>
    console.error(`[${new Date().toJSON()}] ERROR -`, message, ...extra),
  time: (label?: string) => console.time(label),
  timeEnd: (label?: string) => console.timeEnd(label),
  captureAxiosError: (message, error) =>
    console.error(
      `[${new Date().toJSON()}] Axios ${error?.response?.status} -`,
      message,
      error?.response?.data
    ),
  captureEvent: (event) => deadEnd,
};
const serverLogs: Logger = {
  log: (message?: any, ...extra: any[]) =>
    console.log(`[${new Date().toJSON()}] LOG   -`, message, ...extra),
  info: (message?: any, ...extra: any[]) =>
    console.info(`[${new Date().toJSON()}] INFO  -`, message, ...extra),
  warn: (message?: any, ...extra: any[]) =>
    console.warn(`[${new Date().toJSON()}] WARN  -`, message, ...extra),
  debug: (message?: any, ...extra: any[]) =>
    console.debug(`[${new Date().toJSON()}] DEBUG -`, message, ...extra),
  error: (message?: any, ...extra: any[]) =>
    console.error(`[${new Date().toJSON()}] ERROR -`, message, ...extra),
  time: (label?: string) => console.time(label),
  timeEnd: (label?: string) => console.timeEnd(label),
  captureAxiosError: (message, error) =>
    console.error(
      `[${new Date().toJSON()}] Axios ${error?.response?.status} -`,
      message,
      error?.response?.data
    ),
  captureEvent: (event) => captureEvent(event as any),
};
const clientLogs: Logger = {
  log: deadEnd,
  info: deadEnd,
  debug: (message?: any, ...extra: any[]) =>
    captureEvent({
      message,
      level: 'debug',
      release: APP_RELEASE,
      extra: { ...extra },
    }),
  warn: (message?: any, ...extra: any[]) =>
    captureEvent({
      message,
      level: 'warning',
      release: APP_RELEASE,
      extra: { ...extra },
    }),
  error: (message?: any, ...extra: any[]) =>
    captureEvent({
      message,
      level: 'error',
      release: APP_RELEASE,
      extra: { ...extra },
    }),
  time: deadEnd,
  timeEnd: deadEnd,
  captureAxiosError: (message, error) =>
    error?.response?.status === HTTP_401_UNAUTHORIZED
      ? null
      : captureEvent({
          message: `Caught Request: ${message}`,
          level: 'error',
          release: APP_RELEASE,
          extra: {
            error,
            isAxiosError: error?.isAxiosError,
            status: error?.response?.status,
            data: error?.response?.data,
            request: error?.request,
          },
        }),
  captureEvent: (event) => captureEvent(event as any),
};
_global.logger =
  IS_DEV && !RUN_SENTRY_DEV ? debugLogger : IS_SERVER ? serverLogs : clientLogs;

_global.safe = <T>(object: T | null | undefined): boolean => {
  return typeof object !== 'undefined' && object !== null;
};

let _cookiesEnabled;
_global.cookiesEnabled = () => {
  if (typeof _cookiesEnabled !== 'undefined') {
    return _cookiesEnabled;
  } else {
    try {
      // Create cookie
      document.cookie = 'cookietest=1;';
      const ret = document.cookie.indexOf('cookietest=') !== -1;
      // Delete cookie
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
      _cookiesEnabled = ret;
      return ret;
    } catch (e) {
      _cookiesEnabled = false;
      return false;
    }
  }
};

/**
 * When the boolean is safe to use (not null, not undefined)
 * and the value of the bool is not false,
 * add the classname to the list of classnames
 */
_global.cls = <T>(
  bool: boolean | T | null | undefined,
  className = bool,
  classIfFalse = ''
) => {
  return safe(bool) &&
    ((typeof bool === 'boolean' && bool === true) ||
      (typeof bool !== 'boolean' && !!bool))
    ? ` ${className}`
    : classIfFalse === ''
    ? ''
    : ` ${classIfFalse}`;
};

_global.copyToClipboard = (str) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str);
    return;
  }
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.focus();
  el.select();
  el.setSelectionRange(0, str.length);
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

_global.urlToImageServerUrl = (
  src: string,
  width: number = 0,
  height: number = 0,
  rotate: number = 0
) => {
  if (!src || src === '') {
    return src;
  }
  const split =
    src.indexOf('/uploads/') !== -1
      ? '/uploads/'
      : src.indexOf('/assets/') !== -1
      ? '/assets/'
      : undefined;
  if (!split) {
    return src;
  }
  const bucket = 'oleksiak';
  const key = `${split.substr(1)}${src.split(split)[1]}`; // uploads/2018-10-11/1234-asdf-qwer.jpg
  const resize =
    width !== 0 || height !== 0
      ? {
          width,
          height,
        }
      : undefined;
  const edits =
    safe(resize) || rotate !== 0
      ? {
          rotate,
          resize,
        }
      : undefined;
  const obj: DecodedMediaUrl = {
    bucket,
    key,
    edits,
  };
  return `https://oleksiak.propelhq.com/v4/${btoa(JSON.stringify(obj))}`;
};
