import { Event, TimeFrame } from 'api/Serializers/Appointments';
import { ReportType } from 'models/Assistance';
import { getAppointmentTimeFrame } from 'utils/business-logic';

export const canCallHost = (appointment: Event) => {
  const timeFrame = getAppointmentTimeFrame(appointment);
  return (
    [TimeFrame.SameDay, TimeFrame.Within2, TimeFrame.HasStarted].indexOf(
      timeFrame
    ) !== -1
  );
};

export const canCallClient = (appointment: Event) => {
  const timeFrame = getAppointmentTimeFrame(appointment);
  return [TimeFrame.Within2, TimeFrame.HasStarted].indexOf(timeFrame) !== -1;
};

export const celsiusToFahrenheit = (temp: number) =>
  Math.round((temp * 9) / 5 + 32);
export const fahrenheitToCelsius = (temp: number) =>
  Math.round(((temp - 32) * 5) / 9);

export const getReportTypeDescription = (type: ReportType) => {
  switch (type) {
    case 'CHANGE_NUM_PARTICIPANTS':
      return 'Lesson has incorrect number of swimmers';
    case 'CLIENT_NOT_HERE':
      return 'Client did not show up to this lesson';
    case 'CLIENT_CANCEL_REQUEST':
      return 'Client would like to cancel';

    case 'INSTRUCTOR_CANCEL_REQUEST':
      return 'Instructor would like to cancel';
    case 'INSTRUCTOR_NOT_HERE':
      return 'Instructor is not here';

    case 'FACILITY_INACCESSIBLE':
      return 'Pool is closed or inaccessible';
    case 'FACILITY_URGENT_MAINTENANCE':
      return 'Urgent pool maintenance is needed';
    case 'FACILITY_COLD':
      return 'Pool is cold';
    case 'FACILITY_CROWDED':
      return 'Pool is is too crowded to safely continue';
    case 'FACILITY_NEEDS_ATTENTION':
      return 'Facility needs attention';
    case 'GENERAL':
      return 'General report submission';
    default:
      return '';
  }
};
