import {
  FacilityIcon,
  HomeIcon,
  KPIIcon,
  MessagesIcon,
  PayoutIcon,
  ProposalIcon,
  ProxyLoginIcon,
  ReviewIcon,
  ScheduleIcon,
  UserGroupIcon,
} from 'icons';

import { MenuItem } from 'components/account/menu';
import { QueryParams } from 'config';
import { SHARED_ROUTES } from 'utils/routing';
import { isPathnameInRouteRoot, unreadConversations } from '../utils';
import Assistance from './assistance';
import AdminDashboard from './dashboard';
import AdminStats from './earnings';
import Facilities from './facilities';
import InstructorList, { ValidFilters } from './instructors';
import Messages from './messages';
import Payouts from './payouts';
import Proposals from './proposals';
import ProxyLogin from './proxy-login';
import Reviews from './reviews';
import Schedule from './schedule';

export const ADMIN_ROUTES = {
  ...SHARED_ROUTES,
  AVAILABILITY_MAP: {
    PATH: '/account/availability-map/:facilitySlug?',
    ROOT: '/account/availability-map',
  },
  INSTRUCTORS: {
    PATH: '/account/instructors/:instructorSlug?',
    ROOT: '/account/instructors',
    filter: (filter: ValidFilters) =>
      `/account/instructors?${QueryParams.Filter}=${filter}`,
    detail: (instructorSlug: string) =>
      `/account/instructors/${instructorSlug}`,
  },
  STATS: {
    PATH: '/account/earnings',
    ROOT: '/account/earnings',
  },
  PAYOUTS: {
    PATH: '/account/payouts/:entity/:payoutId?',
    ROOT: '/account/payouts/marketplace',
    nav: (entity: '' | 'marketplace' | 'instructors' | 'hosts') =>
      `/account/payouts${entity !== '' ? `/${entity}` : ''}`,
  },
  PROXY_LOGIN: {
    PATH: '/account/proxy-login',
    ROOT: '/account/proxy-login',
  },
};

export const AdminMenuItems: MenuItem[] = [
  {
    id: 'dashboard',
    route: ADMIN_ROUTES.DASHBOARD,
    text: 'Dashboard',
    Icon: HomeIcon,
    isActive: (pathname) => pathname === SHARED_ROUTES.DASHBOARD.ROOT,
    Component: AdminDashboard,
  },
  {
    id: 'assistance',
    route: ADMIN_ROUTES.ASSISTANCE,
    Component: Assistance,
  },
  {
    id: 'facilities',
    route: ADMIN_ROUTES.FACILITIES,
    text: 'Facilities',
    Icon: FacilityIcon,
    isActive: isPathnameInRouteRoot,
    Component: Facilities,
  },
  {
    id: 'schedule',
    route: ADMIN_ROUTES.SCHEDULE,
    text: 'Schedule',
    Icon: ScheduleIcon,
    isActive: isPathnameInRouteRoot,
    Component: Schedule,
    isFixedHeightRoute: true,
  },
  {
    id: 'proposals',
    route: ADMIN_ROUTES.PROPOSALS,
    text: 'Proposals',
    Icon: ProposalIcon,
    isActive: isPathnameInRouteRoot,
    Component: Proposals,
  },
  {
    id: 'instructors',
    route: ADMIN_ROUTES.INSTRUCTORS,
    text: 'Instructors',
    Icon: UserGroupIcon,
    isActive: isPathnameInRouteRoot,
    Component: InstructorList,
  },
  {
    id: 'payouts',
    route: ADMIN_ROUTES.PAYOUTS,
    text: 'Payouts',
    Icon: PayoutIcon,
    isActive: isPathnameInRouteRoot,
    Component: Payouts,
  },
  {
    id: 'earnings',
    route: ADMIN_ROUTES.STATS,
    text: 'Earnings',
    Icon: KPIIcon,
    isActive: isPathnameInRouteRoot,
    Component: AdminStats,
  },
  {
    id: 'reviews',
    route: ADMIN_ROUTES.REVIEWS,
    text: 'Reviews',
    Icon: ReviewIcon,
    isActive: isPathnameInRouteRoot,
    Component: Reviews,
  },
  {
    id: 'messages',
    route: ADMIN_ROUTES.MESSAGES,
    text: 'Messages',
    Icon: MessagesIcon,
    Component: Messages,
    isActive: isPathnameInRouteRoot,
    listSelector: unreadConversations,
    isFixedHeightRoute: true,
  },
  {
    id: 'proxy-login',
    route: ADMIN_ROUTES.PROXY_LOGIN,
    text: 'Proxy login',
    Icon: ProxyLoginIcon,
    Component: ProxyLogin,
    isActive: isPathnameInRouteRoot,
  },
];
